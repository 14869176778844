import React from "react";
import Nav from "../components/nav";
import Socials from "../components/socials";
import "./standard.css";
import Konami from "react-konami-code";

function Contact() {
	let p = "805";
	let ph = "8058";
	let o = "804-7791";
	let one = "047791";
	const phone = (
		<div>
			<a href={"tel:+1" + ph + one}>
				({p})-{o}
			</a>
		</div>
	);
	return (
		<div>
			<Nav />
			<h1>Drop a line!</h1>
			<p>
				Feel free to reach out to me at any of these networks, but if you are in
				need of a quick response, I typically respond to emails within 24 hours.
			</p>
			<Socials />
			<Konami className="card">{phone}</Konami>
			<footer>
				If you want to reach me by cell, you will either have to comb through my
				source code or figure out a way to get an extra 30 lives...
			</footer>
		</div>
	);
}

export default Contact;
